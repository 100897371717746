import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Rob Indesteege</h1>
        <p>Hi, my name is Rob, I'm a full-stack developer. These are some of my favorite technologies:</p>
        <ul style={{ textAlign: 'left' }}>
          <li>Node.js</li>
          <li>React</li>
          <li>Redux</li>
          <li>GraphQL</li>
          <li>Next.js</li>
          <li>Express.js</li>
          <li>Postgresql</li>
        </ul>
        <p>You can contact me at <a className="App-link"
          href="mailto:rob@fixtrack.be"
        >
          rob@fixtrack.be
        </a> or <a className="App-link" href="tel:+32474906221">+32 474 906 221</a></p>
      </header>
    </div>
  );
}

export default App;
